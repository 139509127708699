import React from "react";
import { BsFacebook, BsLinkedin, BsTwitter } from "react-icons/bs";
import { Link } from "react-router-dom";
import LogoImg from "assets/images/logo.png";
import { RiMapPin2Fill } from "react-icons/ri";
import { SlPhone } from "react-icons/sl";
import { HiMailOpen } from "react-icons/hi";

export const Footer = () => {
  return (
    <footer id="contact-us" className="py-8">
      <div className="bg-gray-900 rounded-xl text-white md:mx-32 py-8">
        <div className="flex flex-col space-y-4 items-center justify-center font-semibold mb-6">
          <div className="flex flex-row items-center space-x-2">
            <RiMapPin2Fill size={24} />
            <span className="">Plot 3, 5th Street Industrial Area, Bugolobi, Kampala, Uganda (Uganda) and
            Planeetstraat 59, 2500 Lier, (Belgium)</span>
          </div>
          <div className="flex flex-row items-center space-x-2">
            <SlPhone size={24} />
            <span>
              +256 781 414200
              <br />
              +32 468 21 99 40 
            </span>
          </div>
          <div className="flex flex-row items-center space-x-2">
            <HiMailOpen size={24} />
            <span>info@frontiers-impact.com</span>
          </div>
        </div>
        <img src={LogoImg} alt="Afarm Africa" className="mx-auto h-24" />
        <div className="text-center mb-12">
          <h2 className="text-xl font-semibold mb-12">Follow us</h2>
          <div className="flex justify-center mt-2 text-xl">
            <a
              href="https://twitter.com/gozembo"
              target="_blank"
              className="mx-2"
            >
              <BsTwitter className="" />
            </a>
            <a
              href="https://www.facebook.com/gozembo"
              target="_blank"
              className="mx-2"
            >
              <BsFacebook className="" />
            </a>
            <a
              href="https://www.linkedin.com/company/zembo/"
              target="_blank"
              className="mx-2"
            >
              <BsLinkedin className="" />
            </a>
          </div>
        </div>
        <div className="flex justify-center items-center text-xs md:text-sm text-gray-400">
          <div className="flex">
            <Link to="/" className="mx-2">
              About Us
            </Link>
            <Link to="/" className="mx-2">
              What We Do
            </Link>
            <Link to="/" className="mx-2">
              Our Impact
            </Link>
            <Link to="/" className="mx-2">
              Our Clients
            </Link>
          </div>
        </div>
      </div>
      <div className="text-gray-900 text-center my-4 text-xs md:text-sm">
        All rights reserved . Frontiers Impact . 2024 . Built by{" "}
        <a
          href="https://jonahgeek.vercel.app"
          target="_blank"
          className="text-orange-500"
        >
          Jonahgeek
        </a>
      </div>
    </footer>
  );
};
