import React from "react";
import { BsChevronRight } from "react-icons/bs";
import ReachFarmersImage from "assets/images/reach-farmers.png";

export const ReachFarmers = () => {
  return (
    <div className="bg-[#202020] mt-12">
      <span className="text-lg md:text-3xl mb-4 bg-orange-500 rounded-full p-4 text-white w-fit ml-8 md:ml-32 -top-8 font-semibold">
        How do we reach farmers?
      </span>
      <div className="mt-6 mx-4 md:mx-28 text-lg md:text-xl text-white p-4 md:p-8">
        <div className="mb-8">
          <span>
            We serve farmers directly and also work with partners throughout the
            year, which generally includes:
          </span>
        </div>
        <div className="flex flex-row items-center space-x-4 ml-8 mb-8">
          <BsChevronRight />
          <span>
            Providing quality farm products on credit, which farmers repay over
            the full growing season.
          </span>
        </div>

        <div className="flex flex-row items-center space-x-4 ml-8 mb-8">
          <BsChevronRight />
          <span>
            Delivering these products within walking distance of farmers’ homes,
            in time for planting.
          </span>
        </div>

        <div className="flex flex-row items-center space-x-4 ml-8 mb-8">
          <BsChevronRight />
          <span>
            Training farmers on effective agricultural practices and how to sell
            any harvest surplus.
          </span>
        </div>
      </div>
      <img src={ReachFarmersImage} alt="how to reach farmers" />
    </div>
  );
};
