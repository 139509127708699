import { MainLayout } from "components";
import React from "react";
import BannerImage from "assets/images/image10.png";
import { OurVision } from "../components/OurVision";
import { OurValues } from "../components/OurValues";
import { OurModel } from "../components/OurModel";
import { ReachFarmers } from "../components/ReachFarmers";
import { OurClients } from "../components/OurClients";
import { OurImpact } from "../components/OurImpact";
import { WhatWeDo } from "../components/WhatWeDo";

export const Home = () => {
  return (
    <MainLayout>
      <div
        id="home"
        className="relative  h-screen bg-cover bg-center bg-no-repeat"
        style={{ backgroundImage: `url(${BannerImage})` }}
      >
        <div className="absolute -left-4 -bottom-4 border-t-8 border-orange-500 p-6 md:p-12 bg-green-700 text-white">
          <h1 className="text-3xl md:text-4xl font-bold mb-2">
            We are Frontiers Impact!
          </h1>
          <p className="text-xl md:text-2xl">
            Impacting communities through better returns
          </p>
        </div>
      </div>

      {/* Our Vision */}
      <OurVision />

      {/* Our Values */}
      <OurValues />

      {/* Our Model */}
      <OurModel />

      {/* How do we reach farmers */}
      <ReachFarmers />

      {/* What do we do */}
      <WhatWeDo />

      {/* Our Impact */}
      <OurImpact />

      {/* Our Clients */}
      <OurClients />
    </MainLayout>
  );
};
