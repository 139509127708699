import React from "react";
import ImpactImage from "assets/images/impact.png";
import BigHarvestImage from "assets/images/big-harvest.png";
import HealthyFamilyImage from "assets/images/healthy-family.png";
import ImprovedCommunityImage from "assets/images/improved-communities.png";

export const OurImpact = () => {
  return (
    <div id="our-impact" className="mx-8 md:mx-32 my-12">
      <div className="mb-8">
        <div className="md:ml-16 text-4xl mb-12 text-orange-500 font-semibold">
          Our Impact
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="bg-orange-500 p-12 text-xl md:text-2xl text-white flex items-center justify-center">
            <div className="">
              We envision Africa where all smallholder farmers have improved
              living attributed to the better returns from their gardens.
            </div>
          </div>
          <div>
            <img src={ImpactImage} alt="our impact" />
          </div>
        </div>
      </div>
      <div className="mb-8">
        <div className="text-2xl mb-8 text-orange-500 font-bold">
          Our Impact on Farmers’ Lives
        </div>
        <div className="text-xl md:text-2xl mb-8">
          By 2026 Frontiers Impact aims to provide a market linkage service to 12,000
          farmers, for approximately 4,000MT of produce, worth about US$ 2.4
          million annually. On average, we enable farmers to earn 20% more from
          their produce.
        </div>
        <div className="text-xl md:text-2xl">
          In 2022, we served 5,200 farmers, both directly with a bundle of farm
          services and indirectly by improving access to farm services in entire
          regions, typically through partner organizations.
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 items-center mb-8">
        <div className="flex flex-col items-center">
          <div className="text-xl md:text-2xl text-green-500 font-bold mb-8">
            Big Harvests
          </div>
          <img src={BigHarvestImage} alt="big harvest" />
        </div>
        <div className="text-xl md:text-2xl">
          Smallholder farmers depend on the food they grow to feed their
          families and sell for income. On average, the farmers we directly
          serve increase their farm income by 40%+ per year —from the same plot
          of land—and produce a surplus for the first time.
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 items-center mb-8">
        <div className="text-xl md:text-2xl">
          Farmers we serve directly are more likely to have a steady supply of
          food all year. They also grow and buy more nutritious food for their
          families—like beans, fruit, vegetables, and eggs—which is particularly
          important for children under the age of two
        </div>
        <div className="flex flex-col items-center">
          <div className="text-xl md:text-2xl text-green-500 font-bold mb-8">
            Healthy Families
          </div>
          <img src={HealthyFamilyImage} alt="healthy families" />
        </div>
      </div>

      <div className=" mb-8">
        <div className="text-xl md:text-2xl text-green-500 font-semibold mb-8">
          Improved Communities
        </div>
        <img src={ImprovedCommunityImage} alt="Improved community image" />
      </div>
    </div>
  );
};
